//image change if hover on links
document.addEventListener('DOMContentLoaded', function (){
  const el = document.querySelectorAll('.menu-item.level2')

  el.forEach(item => {
    let imagetochange = item.closest('.level1').querySelector('.submenu__cta')
    let original = imagetochange.dataset.image
    if(original){
      imagetochange.style.backgroundImage = "url("+ original + ")"
    }
    if (item.dataset.image){
        item.parentElement.addEventListener("mouseover", function() {
          imagetochange.style.backgroundSize = "cover"
          imagetochange.style.backgroundImage = "url("+ item.dataset.image + ")"
          imagetochange.style.backgroundPosition= "50% 30%"
        })
        item.parentElement.addEventListener("mouseout", function() {
          imagetochange.style.backgroundImage = "url("+ original + ")"
        })
    } else {
      item.parentElement.addEventListener("mouseover", function() {
        imagetochange.style.backgroundSize = "cover"
        imagetochange.style.backgroundImage = "url("+ original + ")"
      })
    }
  })
})
//Scroll down causes button to go $green
jQuery(document).ready(function($){
		$(window).on('scroll', function() {
				var y_scroll_pos = window.pageYOffset;
				var scroll_pos_test = 150;   // set to whatever you want it to be

				if(y_scroll_pos > scroll_pos_test) {
					$('#contactuslink').addClass('fadegreen')
          $('#contactuslink').parent().css('text-decoration', 'none')
        } else {
          $('#contactuslink').removeClass('fadegreen')
          $('#contactuslink').parent().css('text-decoration', 'auto')
        }
    });
	})

  var subarrow = document.querySelectorAll('.responsive-menu-subarrow')
  subarrow.forEach(item => {
    item.style.setProperty("border-left", "none", "important")
  });
