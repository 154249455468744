/* globals jQuery, wordpress_globals */
import '@/style.scss';

$(document).ready(function() {

  // function walking() {
  //   document.querySelectorAll('.responsive-menu-subarrow').forEach((item, i) => {
  //     item.addEventListener('click', scrollOver(item))
  //   });
  // }
  //
  // setTimeout(function() {
  //   walking()
  // }, 1800)

  let related_sliders = document.querySelectorAll('.related')
  related_sliders.forEach((slider, i) => {
    slider.classList.add('related-slider' + i)
    this_slider = tns({
      'container' : ".related-slider" + i,
      "items": 1,
      "rewind": true,
      "loop": false,
      "swipeAngle": false,
      "speed": 400,
      "controls" : false,
      "nav": true,
      "autoplay": true,
      "navPosition" : "bottom",
      "autoplayButtonOutput": false,
      "gutter" : 20,
      "autoHeight": false,
      "responsive" : {
        768 : {
          "controls" : false,
          "items": 3,
        }
      }
    })
  })

  let target = document.querySelectorAll('em')
  target.forEach((emph, i) => {
    let elem = document.createElement('img')
    if (emph.classList.contains('white')) {
      elem.src = '/wp-content/themes/emc-2020/img/Website Design/Assets/Underline-white.svg'
    } else {
      elem.src = '/wp-content/themes/emc-2020/img/Website Design/Assets/Underline.svg'
    }
    emph.prepend(elem)
  });


    // target ie
    function addIEClasses() {
        var isIE = /*@cc_on!@*/ false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        if (isIE || isEdge) {
            $('body').addClass('ie-styles');
        }
    }
    addIEClasses()

    // Main Menu Dropdown Backgrounds
    calculateSubmenuBackgroundGradients();

    //Cookie control
    /**
     * Get the value of the given cookie.
     *
     * @param {string} name
     *
     * @return {string}
     */
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if(parts.length == 2) {
            return parts.pop().split(";").shift();
        }
    }
    /**
     * Create a cookie with the given values.
     *
     * @param {string} name
     * @param {string} value
     * @param {string} days
     *
     * @return {void}
     */
    function createCookie(name,value,days) {
        if(days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        } else {
            var expires = "";
        }
        document.cookie = name+"="+value+expires+"; path=/";
    }
    // Show the cookie popup if it hasn't been shown yet.
    if(!getCookie('infoBox')) {
        $('.cookie-notice').removeClass('hidden');
    }
    // Handle cookie popup button clicks.
    $('.accept-cookies').click(function(e) {
        e.preventDefault();
        CookieControl.notifyAccept();
        createCookie('infoBox', '1', 90);
        $('.cookie-notice').addClass('hidden');
    });
    $('.manage-cookies').click(function(e) {
        e.preventDefault();
        CookieControl.open();
        createCookie('infoBox', '1', 90);
        $('.cookie-notice').addClass('hidden');
    });

});


$(window).on('load', function() {

    var $container = $('.articles-list__posts').infiniteScroll({
        // options...
        // disable loading on scroll
        path: '.pagination a',
        append: '.articles-list__posts__col',
        history: false,
        loadOnScroll: false
    });

    var $viewMoreButton = $('.pagination a');

    $viewMoreButton.on('click', function(e) {

        e.preventDefault();
        // load next page
        $container.infiniteScroll('loadNextPage');
        // enable loading on scroll
        $container.infiniteScroll('option', {
            loadOnScroll: false,
            path: '.pagination a',
            checkLastPage: true
        });
        // hide button

    });

    $container.on('last.infiniteScroll', function(event, response, path) {
        $viewMoreButton.hide();
    });


    $('.home-hero__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.home-hero__slider__slide__arrows__left'),
        nextArrow: $('.home-hero__slider__slide__arrows__right')
    });

    // $(".latest-news__slider").flickity({
    //   wrapAround: !1,
    //   cellAlign: "center",
    //   pageDots: !1,
    //   initialIndex: 1,
    //   watchCSS: true
    // })

    // $('.image-carousel__slider').flickity({
    //     wrapAround: false,
    //     prevNextButtons: false,
    //     cellAlign: 'center',
    //     groupCells: true,
    //     pageDots: false,
    //     watchCSS: true
    // });

    $('.general-carousel__slider').slick({
      slidesToShow: 1,
      infinite: false,
      dots: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            infinite: true,
            dots: true,
            arrows: true,
          }
        }
      ],
    });

    if ($('.featured-articles__posts').length > 0) {

    var flkty = new Flickity( '.featured-articles__posts', {
        wrapAround: false,
        cellAlign: 'left',
        percentPosition: false,
        resize: false
        //slidesToScroll: 1,
        //variableWidth: true,
        //dots: true,
        //responsive: [{
          //  breakpoint: 767,
            //settings: {
              //  dots: false,
              //  variableWidth: false
           // }
        //}, ]
    })

        jQuery( '.open-contact-popup' ).on( 'click touch', function(e) {
            e.preventDefault();
            jQuery( this ).parents( '.row' ).hide();
            jQuery( this ).parents( 'section' ).find( '.contact-popup' ).show();

            jQuery('html, body').animate({
                scrollTop: jQuery(this).parents( 'section' ).offset().top - 120
            }, '300');


        })

}


    //    flkty.on( 'change', flkty.reposition );

    $('.articles-list__posts__col__wrap').matchHeight();
    $('.contact-block__bottom__block__text').matchHeight();

});


$(window).resize(function() {
    calculateSubmenuBackgroundGradients();
})

function calculateSubmenuBackgroundGradients() {
    var colours = {
        green: '#51C1B0',
        grey: '#d5d5d5',
        greyLight: '#f0f0f0'
    };

    // calc gradient positions for single and multi layouts
    var $wrapContainer = $('.masthead__mastnav__menu__item__submenu__wrap');
    var gradientXSingle = (window.innerWidth - $wrapContainer.outerWidth()) / 2 + $wrapContainer.outerWidth() * .3333;
    var gradientXMulti = (window.innerWidth - $wrapContainer.outerWidth()) / 2 + $wrapContainer.outerWidth() * .6666;

    // loop over each level1 menu item with children
    $('.masthead__mastnav__menu__item.level1.has-children').each(function() {
        var $elem = $(this);
        var $elemSubmenu = $elem.find('.masthead__mastnav__menu__item__submenu')
        var ctaBackground = $elem.data('cta-background');

        if( $elem.hasClass('megamenu_single') ) {
            if( ctaBackground == 'grey' ) {
                setSubmenuBackgroundGradient($elemSubmenu, gradientXSingle, 'white', colours.grey, colours.greyLight);
            } else {
                setSubmenuBackgroundGradient($elemSubmenu, gradientXSingle, 'white', colours.green, colours.green);
            }
        } else if( $elem.hasClass('megamenu_multi') ) {
            if( ctaBackground == 'grey' ) {
                setSubmenuBackgroundGradient($elemSubmenu, gradientXMulti, 'white', colours.grey, colours.greyLight);
            } else {
                setSubmenuBackgroundGradient($elemSubmenu, gradientXMulti, 'white', colours.green, colours.green);
            }
        }
    });
}

function setSubmenuBackgroundGradient(elem, gradientX, colour1, colour2, colour3) {
    elem.css('background-image', 'linear-gradient(to right,' + colour1 + ' ' + gradientX + 'px, ' + colour2 + ' ' + gradientX + 'px', + colour3 + ')');
}

//Scroll to #<insert-id-here>
function scrollingDown(elementId) {
  let element = document.querySelector('#' + elementId)
  const position = element.offsetTop-156;
  window.scroll({
    top: position,
    behavior: 'smooth'
  });
}

function numberTab(evt, tabIndex) {
  // Declare all variables
  var i, tabcontent, tablinks;

  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.getElementsByClassName("tab-output");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.getElementsByClassName("tab-button");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  document.getElementById("tab-" + tabIndex).style.display = "block";
  evt.target.className += " active";
}

function scrollOver(elem) {
  console.log(elem)
  target = elem.parentElement.nextElementSibling
  console.log(target)
  setTimeout(function() {
    target.scrollIntoView({behavior: "smooth"})
  }, 500)
  if(elem.closest('ul').classList.contains('responsive-menu-submenu-depth-1') || elem.closest('ul').classList.contains('responsive-menu-submenu-depth-2')){
    return
  } else {
    depth1 = elem.closest('li').querySelector('ul')
    if (!depth1.querySelector('button')){
      backButton = document.createElement('button')
      backButton.onclick = function() {
        triggerSubArrow(elem)
      }
      backButton.innerHTML = 'Back'
      depth1.prepend(backButton)
    }
  }
}
function triggerSubArrow(subarrow) {
    var sub_menu = $(subarrow).parent().siblings('.responsive-menu-submenu');
    var self = this;
    if(this.accordion == 'on') {
        /* Get Top Most Parent and the siblings */
        var top_siblings = sub_menu.parents('.responsive-menu-item-has-children').last().siblings('.responsive-menu-item-has-children');
        var first_siblings = sub_menu.parents('.responsive-menu-item-has-children').first().siblings('.responsive-menu-item-has-children');
        /* Close up just the top level parents to key the rest as it was */
        top_siblings.children('.responsive-menu-submenu').slideUp(self.subMenuTransitionTime, 'linear').removeClass('responsive-menu-submenu-open');
        /* Set each parent arrow to inactive */
        top_siblings.each(function() {
            $(this).find('.responsive-menu-subarrow').first().html(self.inactiveArrow);
            $(this).find('.responsive-menu-subarrow').first().removeClass('responsive-menu-subarrow-active');
        });
        /* Now Repeat for the current item siblings */
        first_siblings.children('.responsive-menu-submenu').slideUp(self.subMenuTransitionTime, 'linear').removeClass('responsive-menu-submenu-open');
        first_siblings.each(function() {
            $(this).find('.responsive-menu-subarrow').first().html(self.inactiveArrow);
            $(this).find('.responsive-menu-subarrow').first().removeClass('responsive-menu-subarrow-active');
        });
    }
    if(sub_menu.hasClass('responsive-menu-submenu-open')) {
        sub_menu.slideUp(self.subMenuTransitionTime, 'linear').removeClass('responsive-menu-submenu-open');
        $(subarrow).html(this.inactiveArrow);
        $(subarrow).removeClass('responsive-menu-subarrow-active');
    } else {
        sub_menu.slideDown(self.subMenuTransitionTime, 'linear').addClass('responsive-menu-submenu-open');
        $(subarrow).html(this.activeArrow);
        $(subarrow).addClass('responsive-menu-subarrow-active');
    }
    document.querySelector('html').classList.add('responsive-menu-open')
}

if(document.querySelector('.cuts-accordion')) {
  let acc = document.querySelectorAll('.cuts-accordion')
  acc.forEach((item, i) => {

    let accordions = item.querySelectorAll('.accordion-item');

    accordions.forEach((accordion, i) => {

      let content = accordion.querySelector('.accordion-content');

      accordion.querySelector('.accordion-title').addEventListener('click', (e) => {
        e.preventDefault();

        accordion.classList.toggle('active');

        // Hide all other accordions
        accordions.forEach((acc, i) => {
          if(acc !== accordion) {
            acc.classList.remove('active');
          }
        });
      }
    )});
  });
}

if(document.querySelector('.cut-group')) {
  let group = document.querySelectorAll('.cut-group')
  group.forEach((item, i) => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      item.classList.toggle('active');

      let id = item.getAttribute('id');

      document.getElementById('beef-cuts-select').value = id;
      document.getElementById('beef-cuts-select').dispatchEvent(new Event('change'));

      // Remove active class from all other groups
      // group.forEach((group, i) => {
      //   if(group !== item) {
      //     group.classList.remove('active');
      //   }
      // });
    });
  });
}

document.getElementById('beef-cuts-select').addEventListener('change', (e) => {
  let value = e.target.value;

  if(value !== "") {
    let group = document.getElementById(value);

    group.classList.toggle('active');
  }

  if(document.getElementById(value + "-accordion")){
    document.getElementById(value + "-accordion").classList.toggle('active');
  }

  // Hide all other accordions
  let accordions = document.querySelectorAll('.beef-cut-info');

  accordions.forEach((accordion, i) => {
    if(accordion.getAttribute('id') !== value + "-accordion") {
      accordion.classList.remove('active');
    }else{
      accordion.classList.add('active');
    }
  });

  // Remove active class from all other groups
  let groups = document.querySelectorAll('.cut-group');
  groups.forEach((group, i) => {
    if(group.getAttribute('id') !== value) {
      group.classList.remove('active');
    }else{
      group.classList.add('active');
    }
  });

  console.log(value);
  if(value === "") {
    document.getElementById('select-a-cut').classList.add('active');
  } else {
    document.getElementById('select-a-cut').classList.remove('active');
  }


});

import '@/js/custom/form.js';
import '@/js/custom/imagechange.js';