/**
 * Script to handle the filtering of all post types
 */

$(document).ready(function() {

    var $filterForm = $('#post-filter');
    var filters = $filterForm.find('select');

    // register even listeners
    for(var i = 0; i < filters.length; i++) {
        $(filters[i]).on('change', function(e) {
            // submit the form
            $filterForm.submit();
        })
    }
 

    $filterForm.on('submit', function(e) {
        e.preventDefault();

        $.ajax({
            url: $filterForm.attr('action'),
            data: $filterForm.serialize(),
            type: $filterForm.attr('method'),
            beforeSend: function(xhr) {
                // add loading feedback here
                // good UX
            },
            success: function(data) {
                // output the data to the page
                $('#post-list').empty().append(data);
            }
        });
    });

});
